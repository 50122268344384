import * as React from "react"
import Block from "../components/footer-001"
import config from "../config"

export default function Example() {

    const { site } = config

  return (
            
    <Block
      name=" "
      links={[
        {
          title: "",
          href: "#",
        },
        {
          title: "Eviction Metrics",
          href: "/general_eviction",
        },
        {
          title: "Maps",
          href: "/sda",
        },
        {
          title: "Code Violations & Evictions",
          href: "/ilpr",
        },
        {
          title: "Questions & Feedback",
          href: "https://forms.gle/Muv2yP8QmpqTfbc27",
        },
      ]}
      iconLinks={[
        {
          title: "Follow on Twitter",
          href: "#",
          name: "twitter",
        },
        {
          title: "Follow on Instagram",
          href: "#",
          name: "instagram",
        },
      ]}
      copyright={site.copyright}
    />
  )
}