import * as React from "react"
import McwSvg from '../components/MCW-SVG'

export default function Block({
}) {
  return (
      
        <div textAlign="center" alignItems="center" m="0">
            <McwSvg></McwSvg>
          </div>
  )
}

