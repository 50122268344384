import { Layout } from "../../../../src/components";
import Cards from "../../../../src/blocks/cards";
import { Icon } from "reflexjs";
import Block from "../../../../src/components/cta.js";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  Layout,
  Cards,
  Icon,
  Block,
  Link,
  React
};