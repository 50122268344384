import * as React from "react"

export default function VizPage({ children, heading }) {
  return (
    <section py="6|8|12|20" >
      <div maxWidth="1000px" variant="container">
        <div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="left"
          textAlign="left"
        >
        <h1 variant="heading.h2" fontWeight="bolder" lineHeight="tight" mb='6'>
                {heading}
              </h1>
        <main>{ children } </main>
        </div>
      </div>
    </section>
  )
}