export default {
  site: {
    branding: {
      name: "Track Milwaukee Evictions",
    },
    copyright: `© ${new Date().getFullYear()} Track Milwaukee Evictions`,
    links: [
      {
        title: "Eviction Metrics",
        href: "/general_eviction",
      },
      {
        title: "Maps",
        href: "/sda",
      },
      {
        title: "Code Violations & Evictions",
        href: "/ilpr",
      },
      {
        title: "About",
        href: "/about",
      },
    ],
  },
}
