import { Layout } from "../../../../src/components";
import Block from "../../../../src/components/cta.js";
import VizPage from "../../../../src/components/vizPage.js";
import Logo from "../../../../src/blocks/logo";
import McwSvg from "../../../../src/components/MCW-SVG";
import * as React from 'react';
export default {
  Layout,
  Block,
  VizPage,
  Logo,
  McwSvg,
  React
};