import * as React from "react"
import Helmet from "react-helmet"
import { Navbar } from "."
import Footer from "../blocks/footerBlock"
import config from "../config"

export function Layout({ children }) {
  const { site } = config
  return (
    <>
    <Helmet>
      <title>Track Milwaukee Evictions</title>
    </Helmet>
      <Navbar branding={site.branding} links={site.links} />
      <main>{children}</main>
      <Footer ></Footer>
    </>
  )
}
